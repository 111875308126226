import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// 路由配置
export const constantRoutes = [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home/index.vue'),
      redirect: '/main',//页面默认加载的路由
      children: [
        {
          path: "/main",
          name: "main",
          component: () => import('@/views/Home/dOne/index.vue'),
        },
        {
          path: "/toal",
          name: "toal",
          component: () => import('@/views/Home/dTwo/index.vue'),
        }
        ,
        {
          path: "/mainToal",
          name: "toal",
          component: () => import('@/views/Home/mainToal/index.vue'),
        }
      ]
    }

]
const createRouter = () => new Router({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})
const router = createRouter()
export default router
